import BaseRenderMixin from "@/components/base/BaseRenderMixin";

const BaseSingleTagComponent = () => import("@/components/base/tag/BaseSingleTagComponent")


export default {
    name: "BaseMultiTagComponent",
    mixins: [BaseRenderMixin],
    props: {
        tag_data: Array
    },
    methods: {
        make_element_tree(createElement, element_data, options = {}, context = null) {
            let data = {}
            let el, props, attrs, style, children

            [el, props] = this.get_el_props(element_data, context)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)

            if (props) data.props = props
            if (attrs) data.attrs = attrs
            if (style) data.style = style

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_el_props(element_data, context) {
            let el = element_data.tag
            let props = null

            if (element_data.attr && 'deepsel-tag' in element_data.attr) {
                el = BaseSingleTagComponent
                props = {
                    component_data: element_data,
                    tag_data: context.tag_data
                }
            }

            return [el, props]
        },
        get_children(element_data, createElement) {
            let children = []
            let single_tag_children = false

            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {
                        if (item.attr && 'deepsel-tag' in item.attr) {
                            if (single_tag_children) continue
                            single_tag_children = true
                            for (let tag_data of this.$props.tag_data) {
                                children.push(this.make_element_tree(createElement, item, {}, {tag_data: tag_data.toString()}))
                            }

                        } else {
                            children.push(this.make_element_tree(createElement, item));
                        }
                    } else if (item.node === "text") children.push(item.text)
                }
            }

            return children
        },
    },
}